import { useEffect, useState } from "react";
import AOS from "aos";
import { TypeAnimation } from "react-type-animation";
import "aos/dist/aos.css";
import logoApp from "./assets/Web/logo.svg";
import imgPepeThink from "./assets/Web/logo-1.svg";
import imgTwitter from "./assets/images/imgTwitter.webp";
import imgTelegram from "./assets/images/imgTelegram.webp";
import imgLand from "./assets/images/imgLand.svg";
import imgPepeHatThink from "./assets/Web/logo-2.svg";
import imgH2B1 from "./assets/images/imgH2B1.png";
import imgH2B2 from "./assets/images/imgH2B2.png";
import imgH2B3 from "./assets/images/imgH2B3.png";
import imgH2B4 from "./assets/images/imgH2B4.png";

import footerLogo from "./assets/Web/Capa_2.svg";

import iconMenu from "./assets/images/iconMenu.png";
import iconClose from "./assets/images/iconClose.png";
import iconUp from "./assets/images/iconUp.png";

import uniswap from "./assets/images/uniswap.webp";
import cmc from "./assets/images/cmc.webp";
import etherscan from "./assets/images/etherscan.webp";
import dextool from "./assets/images/dextools.webp";

import "./App.css";

function App() {
  const [isActive, setIsActive] = useState(false);
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    AOS.init({
      duration: 800,
      disable: "mobile",
    });
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 90) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="App overflow-hidden">
      <header
        className="page-header w-screen drop-shadow-md py-4 px-6
       bg-[#B22FAF]">
        <div className="container mx-auto flex items-center justify-between">
          <img
            data-aos="fade-up"
            className="w-[100px]  lg:w-[131px]  object-cover"
            src={logoApp}
            alt="logo"
          />
          <nav data-aos="fade-up" className="hidden lg:block">
            <ul className="flex text-lg">
              <li className="p-[10px] m-1 text-white">
                <a href="/">home</a>
              </li>
              <li className="p-[10px] m-1 text-white">
                <a href="#about">about</a>
              </li>
              <li className="p-[10px] m-1 text-white">
                <a href="#buy">how to buy</a>
              </li>
              <li className="p-[10px] m-1 text-white">
                <a href="#tokenomics">tokenomics</a>
              </li>
              {/* <li className="p-[10px] m-1 text-white">
                  <a href="/">roadmap</a>
                </li> */}
            </ul>
          </nav>
          <button
            data-aos="flip-left"
            className="text-white rounded-[60px] border border-white w-[147px] h-[45px] hidden lg:block">
            buy now
          </button>
          <button onClick={() => setIsActive(true)} className="block lg:hidden">
            <img src={iconMenu} alt="menu" />
          </button>
        </div>
      </header>
      <main className="bg-[#B22FAF]">
        <div className="relative">
          <section className="section-spepe bg-contain min-h-screen bg-no-repeat opacity-20"></section>
          <div
            className="absolute left-0 right-0 top-0 container mx-auto px-6 lg:px-[128px] py-[60px]
           flex flex-col lg:flex-row items-center">
            <div className="content-left text-center">
              <div data-aos="fade-right" className="lg:text-left">
                <h2 className="text-[60px] text-white font-black uppercase font-title">
                  Father Wall Street
                </h2>
                <p className="text-[18px] lg:text-[28px] text-white leading-10 mb-12">
                  The story of a man known as the "Godfather of Wall Street",
                  who bridges the traditional financial world of Wall Street
                  with blockchain technology's decentralized and transparent
                  capabilities.
                </p>
              </div>
              <div className="flex gap-5 justify-center lg:justify-start">
                <a
                  data-aos="zoom-in"
                  href="https://twitter.com/FWSErc20"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img className="w-20" src={imgTwitter} alt="" />
                </a>
                <a
                  data-aos="zoom-in"
                  href="https://t.me/FatherWallStreet"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img className="w-20" src={imgTelegram} alt="" />
                </a>
                <a
                  data-aos="zoom-in"
                  href="https://www.dextools.io/app/en/ether/pair-explorer/0x6417b9a6559f99633dfa96f0b1858669547acd26"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img className="w-20" src={dextool} alt="" />
                </a>
                <a
                  data-aos="zoom-in"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img className="w-20" src={etherscan} alt="" />
                </a>
                <a
                  data-aos="zoom-in"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img className="w-20" src={cmc} alt="" />
                </a>
                <a
                  data-aos="zoom-in"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img className="w-20" src={uniswap} alt="" />
                </a>
              </div>
            </div>
            <div data-aos="fade-left" className="content-right w-full h-full">
              <img
                className="w-[280px] mx-auto lg:w-[476px] overflow-hidden object-cover bg-cover"
                src={imgPepeThink}
                alt=""
              />
            </div>
          </div>
        </div>
        <section className="section-about" id="about">
          <img
            src={imgLand}
            className="rotate-180 h-[18px] w-full object-cover"
            alt=""
          />
          <div className="container mx-auto px-6 lg:px-[120px] lg:gap-[60px] py-[80px] flex flex-col-reverse lg:flex-row lg:items-center">
            <div data-aos="fade-right" className="content-left flex-shrink-0">
              <img
                className="w-[280px] lg:w-[400px] mx-auto"
                src={imgPepeHatThink}
                alt=""
              />
            </div>
            <div className="content-right break-words">
              <h2 className="font-title text-[27px] uppercase lg:text-[56px] pb-[40px] font-black text-white text-left lg:text-center drop-shadow-[0_2px_2px_rgba(0,0,0,1)]">
                about
              </h2>
              <p
                data-aos="fade-left"
                data-aos-duration="400"
                className="text-[18px] break-words lg:text-[23px] text-white ">
                In the heart of New York City's bustling financial district,
                there lived a man known as the "Godfather of Wall Street." His
                name was Benjamin Sterling, or simply "Ben" on Wall Street. Ben
                was a talented financier who bridged the gap between traditional
                Wall Street and blockchain technology.
              </p>
              <p
                data-aos="fade-left"
                data-aos-duration="500"
                className="text-[18px] lg:text-[23px] pt-7 text-white">
                Born into a modest family in Brooklyn, Ben quickly rose to fame
                in the world of finance. However, his vision extended further,
                recognizing the potential of blockchain to ensure transparency,
                security, and decentralization in finance.
              </p>
              <p
                data-aos="fade-left"
                data-aos-duration="600"
                className="text-[18px] lg:text-[23px] pt-7 text-white">
                Ben left his traditional job to establish Sterling Blockchain
                Solutions, specializing in connecting Wall Street with
                blockchain technology. He persuaded Wall Street to invest in
                cryptocurrencies and developed blockchain solutions for
                traditional finance.
              </p>
              <p
                data-aos="fade-left"
                data-aos-duration="700"
                className="text-[18px] lg:text-[23px] pt-7 text-white">
                Under Ben's leadership, Wall Street embraced blockchain
                technology, leading to a comprehensive transformation of the
                financial industry. Ben Sterling became the "Godfather of Wall
                Street," embodying a unique blend of tradition and innovation.
              </p>
            </div>
          </div>
        </section>

        <section id="buy" className="section-how-to-buy">
          <img
            src={imgLand}
            className="rotate-180 h-[18px] w-full object-cover"
            alt=""
          />
          <div className="container mx-auto px-[50px] py-[60px]">
            <h2 className="font-title text-[27px] uppercase font-black lg:text-[56px] pb-[40px] text-white text-center drop-shadow-[0_2px_2px_rgba(0,0,0,1)]">
              how to buy
            </h2>
            <div
              className="item bg-[#7E227C] flex flex-col lg:flex-row gap-[60px] items-center px-5 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2">
              <div className="w-[221px] shrink-0">
                <img className="w-[60%] mx-auto" src={imgH2B1} alt="" />
              </div>
              <div className="lg:text-left text-center">
                <h3 className="text-[18px] font-bold lg:text-[23px] pb-4">
                  Create a wallet
                </h3>
                <TypeAnimation
                  cursor={false}
                  sequence={[
                    // Same substring at the start will only be typed out once, initially
                    "download metamask or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to metamask.io.",
                  ]}
                  wrapper="span"
                  speed={50}
                  className="text-[15px] lg:text-[19px]"
                />
              </div>
            </div>
            <div
              className="item bg-[#7E227C] flex flex-col lg:flex-row gap-[60px] items-center px-5 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2">
              <div className="w-[221px] shrink-0">
                <img className="w-[60%] mx-auto" src={imgH2B2} alt="" />
              </div>
              <div className="lg:text-left text-center">
                <h3 className="text-[18px] lg:text-[23px] font-bold pb-4">
                  Get Some ETH
                </h3>
                <TypeAnimation
                  cursor={false}
                  sequence={[
                    "have ETH in your wallet to switch to $FWS. If you don’t have any ETH, you can buy directly on metamask, transfer from another wallet, or buy on another exchange and send it to your wallet.",
                  ]}
                  wrapper="span"
                  speed={50}
                  className="text-[15px] lg:text-[19px]"
                />
              </div>
            </div>
            <div
              className="item bg-[#7E227C] flex flex-col lg:flex-row gap-[60px] items-center px-5 lg:px-[60px] py-[32px] text-[23px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2">
              <div className="w-[221px] shrink-0">
                <img className="w-[60%] mx-auto" src={imgH2B3} alt="" />
              </div>
              <div className="lg:text-left text-center">
                <h3 className="text-[18px] lg:text-[23px] font-bold pb-4">
                  Go to Uniswap
                </h3>
                <TypeAnimation
                  cursor={false}
                  sequence={[
                    "connect to Uniswap. Go to app.uniswap.org in google chrome or on the browser inside your Metamask app. Connect your wallet. Paste the $FWS token address into Uniswap, select $FWS, and confirm. When Metamask prompts you for a wallet signature, sign.",
                  ]}
                  wrapper="span"
                  speed={50}
                  className="text-[15px] lg:text-[19px]"
                />
              </div>
            </div>
            <div
              className="item bg-[#7E227C] flex flex-col lg:flex-row gap-[60px] items-center px-5 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2">
              <div className="w-[221px] shrink-0">
                <img src={imgH2B4} className="mx-auto" alt="" />
              </div>
              <div className="lg:text-left text-center">
                <h3 className="text-[18px] lg:text-[23px] font-bold pb-4">
                  Switch ETH for $FWS
                </h3>
                <TypeAnimation
                  cursor={false}
                  sequence={["Swap ETH for $FWS."]}
                  wrapper="span"
                  speed={50}
                  className="text-[15px] lg:text-[19px]"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="tokenomics" className="section-tokenomics">
          <img
            src={imgLand}
            className="rotate-180 h-[18px] w-full object-cover"
            alt=""
          />
          <div className="container mx-auto px-[50px] py-[60px]">
            <h2 className="font-title font-black text-[27px] lg:text-[56px] pb-[40px] text-white text-center drop-shadow-[0_2px_2px_rgba(0,0,0,1)]">
              Tokenomics
            </h2>
            <div className="content flex flex-col lg:flex-row gap-[60px] justify-center lg:px-[90px] text-white">
              <div>
                <p
                  data-aos="zoom-in"
                  className=" leading-8 text-center text-3xl font-bold">
                  Total supply
                  <br />
                  100,000,000
                </p>
                <div
                  className="item bg-[#7E227C] mt-[38px] flex px-5 lg:px-[60px] text-[23px] py-[32px] text-white mb-[29px] 
            rounded-tr-3xl rounded-bl-3xl border-white border-2 text-center">
                  {/* <TypeAnimation
                    cursor={false}
                    sequence={[
                      "TAX 0/0 \n\n Pepe PoW, the ultimate meme ruler! Tired of the ShibaCumGMElonKishuTurboAssFlokiMoon Inu craze, Pepe PoW is here to reclaim the throne of memes. With a stealth launch, zero taxes, and a renounced contract, $FWS is the people's coin for eternity. Say goodbye to boring memecoins and join the revolution of pure memetic power! Let Pepe PoWguide you towards greatness and make memecoins fun again. Long live the meme king!",
                    ]}
                    speed={20}
                    className="text-sm lg:text-[19px] whitespace-pre-line w-full break-words text-center"
                  /> */}
                  <p>
                    Tax 0/0
                    <br />
                    No Airdrop, LP Burnt
                    <br />
                    Contract Renounced
                  </p>
                </div>
              </div>
              {/* <div data-aos="fade-left" className="flex flex-col items-center">
                <p className="text-center">
                  CEX
                  <br />
                  6.9
                </p>
                <div className="w-[250px] h-[250px] lg:w-[433px] lg:h-[433px] my-6 object-cover shrink-0">
                  <img src={imgPepeMeme} alt="" />
                </div>
                <p className="text-center">
                  Liquidity Pool
                  <br />
                  93.1%
                </p>
              </div> */}
            </div>
          </div>
        </section>

        {/* <section className="section-roadmap">
          <img
            src={imgLand}
            className="rotate-180 h-[18px] w-full object-cover"
            alt=""
          />
          <div className="container mx-auto px-[50px] py-[60px]">
            <p className="text-lg leading-7 text-white text-center">
              Pepe PoW, the ultimate turbocharged Pepe experience! This isn't
              your average Pepe; it's a Pepe that works wonders! Get ready to be
              blown away by the exhilaration of proof-of-work power. Pepe PoW
              takes fun to a whole new level, delivering a knockout punch of
              excitement. Bid farewell to the ordinary and embrace the
              powerhouse of Pepe PoW! With a team of seasoned Devs and Crypto
              analysts, the adventure is about to begin. Brace yourself for the
              ride of a lifetime! Get your hands on Pepe PoW now!
            </p>
          </div>
        </section> */}
      </main>
      <footer className="bg-[#B22FAF]">
        <div className="container mx-auto">
          <div className="max-w-xs mx-auto">
            <img src={footerLogo} alt="" />
          </div>
          <p className="py-[17px] text-center text-[13px] text-white">
            dev@fatherwallstreet.com
            <br />
            0x748C426b3a8bbE9865eA75E6ac56ECF5164749aC
            <br />© 2023 by $FWS All rights reserved!
          </p>
        </div>
      </footer>
      {isShow && (
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          className="lg:hidden w-[44px] h-[44px] flex items-center justify-center
           bg-white border border-black rounded-[50%] fixed bottom-11 right-11">
          <img className="w-[28px]" src={iconUp} alt="" />
        </button>
      )}
      {isActive && (
        <div className="menu-sp animate-fadeInDown w-full h-screen bg-[#B22FAF] fixed left-0 right-0 top-0 bottom-0 overflow-hidden">
          <div className="flex justify-end">
            <button className="my-6 mr-6" onClick={() => setIsActive(false)}>
              <img className="w-[25px] h-[25px]" src={iconClose} alt="" />
            </button>
          </div>
          <nav>
            <ul className="text-[16px] text-center">
              <li className="p-[10px] mb-6 text-white">
                <a href="/">home</a>
              </li>
              <li className="p-[10px] mb-6 text-white">
                <a href="#about">about</a>
              </li>
              <li className="p-[10px] mb-6 text-white">
                <a href="#buy">how to buy</a>
              </li>
              <li className="p-[10px] mb-6 text-white">
                <a href="#tokenomics">tokenomics</a>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
}

export default App;
